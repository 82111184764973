import React, { useEffect } from "react";
import { actionCreators } from "../store/RequestListState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { 
    Typography, Paper, Grid, TextField, IconButton, InputAdornment, Table, 
    TableContainer, TableHead, TableBody, TableCell, TableRow, Box, Link
} from "@mui/material";
import { useAppStyles } from "../styles";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, Link as RouterLink } from "react-router-dom";

const SearchBar = props => {
    const { searchTerm, filterList, loading } = props;

    return (<TextField sx={{ width: "100%" }}
        onChange={(event) => filterList(event.currentTarget.value)}
        id="search-term"
        variant="outlined"
        value={searchTerm}
        disabled={loading}
        inputProps={{
            placeholder: "Въведете клиентски номер или номер на заявка",
            sx: { padding: "8px 16px" }
        }}
        InputProps={{
            endAdornment: searchTerm && searchTerm.length > 0 ? (
                <InputAdornment position="end">
                    <IconButton
                        size="small"
                        aria-label="clear search"
                        onClick={() => {
                            filterList("");
                        }}
                        edge="end"
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            ) : null
        }} />
    )
};

const RequestListPage = props => {
    const { resetState, getData, filterList, searchTerm, loading, dealLoading, filteredList } = props;
    const { classes: appClasses } = useAppStyles();
    const navigate = useNavigate();

    useEffect(() => {
        getData(navigate);

        return () => {
            resetState();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">{`История`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <SearchBar searchTerm={searchTerm} filterList={filterList} loading={loading || dealLoading} />
            </Grid>
            <Grid item xs={12}>
                <TableContainer sx={{ marginTop: "35px" }} component={Box}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Заявка #</TableCell>
                                <TableCell>Клиент #</TableCell>
                                <TableCell>Размер на кредита</TableCell>
                                <TableCell>Срок на кредита</TableCell>
                                <TableCell>Месечен доход</TableCell>
                                <TableCell>Лихва</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredList.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"><Link component={RouterLink} to={ `/result/${row.id}` }>{row.id}</Link></TableCell>
                                    <TableCell>{row.clientId}</TableCell>
                                    <TableCell>{row.requestedAmount}</TableCell>
                                    <TableCell>{row.creditDuration}</TableCell>
                                    <TableCell>{row.salary}</TableCell>
                                    <TableCell>{row.interest}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default connect(
    (state) => state.requestList,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(RequestListPage)
