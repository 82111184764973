import ApiClient from "../services/ApiClient";

const requestGetListType = "REQUEST_GET_LIST";
const completeGetListType = "COMPLETE_GET_LIST";

const filterListType = "FILTER_LIST";

const errorType = "ERROR_LIST";
const resetStateType = "RESET_LIST_STATE_TYPE";

const initialState = {
    loading: true,
    searchTerm: "",
    fullList: [],
    filteredList: []
};

export const actionCreators = {
    getData: (navigate) => async (dispatch) => {
        dispatch({ type: requestGetListType });
        try {
            const response = await ApiClient.get(`api/list`, navigate, dispatch);
            dispatch({ type: completeGetListType, list: response.data });
        } catch {
            dispatch({ type: errorType });
        }
    },
    filterList: (searchTerm) =>  async (dispatch) => {
        dispatch({ type: filterListType, searchTerm });
    },
    resetState: () => async (dispatch) => {
        dispatch({ type: resetStateType });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestGetListType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === completeGetListType) {
        return {
            ...state,
            loading: false,
            fullList: action.list.requests,
            filteredList: action.list.requests
        };
    }

    if (action.type === filterListType) {
        if(action.searchTerm.length === 0) {
            return { 
                ...state,
                searchTerm: "",
                filteredList: [ ...state.fullList ]
            };
        }

        return {
            ...state,
            searchTerm: action.searchTerm,            
            filteredList: state.filteredList
                .filter(r => r.id.toLowerCase().startsWith(action.searchTerm.toLowerCase()) 
                        || r.clientId.toLowerCase().startsWith(action.searchTerm.toLowerCase()))
        };
    }

    if (action.type === errorType) {
        return {
            ...state,
            loading: false
        };
    }

    if (action.type === resetStateType) {
        return { ...initialState };
    }

    return state;
};