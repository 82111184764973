import ApiClient from "../services/ApiClient";

const requestLoginType = "REQUEST_LOGIN";
const completeLoginType = "COMPLETE_LOGIN";

const errorType = "ERROR_LOGING";

const initialState = {
    loading: false
};

export const actionCreators = {
    login: (data, navigate) => async (dispatch) => {
        dispatch({ type: requestLoginType });
        try {
            await ApiClient.post(`api/login`, data, navigate, dispatch);
            dispatch({ type: completeLoginType });
            navigate("/");
        } catch {
            dispatch({ type: errorType });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLoginType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === completeLoginType || action.type === errorType) {
        return {
            ...state,
            loading: false
        };
    }

    return state;
};