import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormHiddenField = props => {
	const { control } = useFormContext();
	const { defaultValue, name, rules } = props;
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<input type="hidden" value={value ?? ""} />
			)}
			defaultValue={defaultValue ?? null}
		/>
	);
};

export default FormHiddenField;