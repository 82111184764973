import React from "react";
import { actionCreators } from "./store/AppState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, AppBar, Container, Toolbar, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import { Routes, Route, useLocation, useNavigate, matchPath } from "react-router-dom";
import CreateRequestPage from "./pages/CreateRequestPage";
import RequestResultPage from "./pages/RequestResultPage";
import RequestListPage from "./pages/RequestListPage";
import PercentIcon from '@mui/icons-material/Percent';
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import { RequireAuthentication } from "./RequireAuthentication";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useLayoutStyles = makeStyles({ name: "Layout" })((theme, params, classes) => ({
    mainContent: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),
        [`&.${classes.mainContentCentered}`]: {
            maxWidth: "1000px",
            margin: "auto",
        }
    },
    mainContentCentered: {},
}));

const Layout = props => {
    const { logout, authenticatedUser } = props;

    const { classes } = useLayoutStyles();
    let location = useLocation();
    let navigate = useNavigate();

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ border: "2px solid white", borderRadius: "50%", padding: "5px", display: "flex", marginRight: "20px" }}>
                            <PercentIcon sx={{ display: "flex" }} />
                        </Box>                       
                        <Box sx={{ flexGrow: 1, display: "flex" }}>
                            {
                                authenticatedUser && (
                                    <>
                                        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                                            <Button                                   
                                                onClick={() => navigate("/")}
                                                sx={{ my: 2, color: matchPath("/", location.pathname) ? '#90caf9' : 'white', display: 'block' }}
                                            >
                                                {"Нова заявка"}
                                            </Button>
                                            <Button                                   
                                                onClick={() => navigate("/list")}
                                                sx={{ my: 2, color: matchPath("list", location.pathname) ? '#90caf9' : 'white', display: 'block' }}
                                            >
                                                {"История"}
                                            </Button>
                                            <Button                                   
                                                onClick={() => navigate("/settings")}
                                                sx={{ my: 2, color: matchPath("settings", location.pathname) ? '#90caf9' : 'white', display: 'block' }}
                                            >
                                                {"Настройки"}
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <AccountCircleIcon sx={{ paddingBottom: "3px" }} />
                                                <Box sx={{ marginLeft: "5px", paddingBottom: "3px", marginRight: "5px", lineHeight: "1.75" }} >{authenticatedUser}</Box>
                                           
                                            <Button                                   
                                                onClick={() => logout(navigate)}
                                                color="secondary"
                                                sx={{ my: 2, display: 'block' }}
                                            >
                                                {"Излез"}
                                            </Button>
                                        </Box>                                        
                                    </>
                                )
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <main className={clsx(classes.mainContent, classes.mainContentCentered)}>
                {props.children}
            </main>
        </>
    );
}

const App = props => {
    const { logout, setUser, authenticatedUser } = props;

    return (
        <Layout logout={logout} setUser={setUser} authenticatedUser={authenticatedUser}>
            <Routes>
                <Route path="/" element={<RequireAuthentication setUser={setUser}><CreateRequestPage /></RequireAuthentication>} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="result/:requestId" element={<RequireAuthentication setUser={setUser}><RequestResultPage /></RequireAuthentication>} />
                <Route path="list" element={<RequireAuthentication setUser={setUser}><RequestListPage /></RequireAuthentication>} />
                <Route path="settings" element={<RequireAuthentication setUser={setUser}><SettingsPage /></RequireAuthentication>} />
            </Routes>
        </Layout>
    );
}

export default connect(
    (state) => state.app,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(App)
