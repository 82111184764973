import ApiClient from "../services/ApiClient";

const requestSettingsType = "REQUEST_SETTINGS";
const completeSettingsType = "COMPLETE_SETTINGS";

const requestUpdateSettingsType = "REQUEST_UPDATE_SETTINGS";
const completeUpdateSettingsType = "COMPLETE_UPDATE_SETTINGS";

const errorType = "ERROR_SETTINGS";
const resetStateType = "RESET_STATE_SETTINGS";

const initialState = {
    initialLoading: true,
    loading: false,
    settings: { optimalScenarios: [] }
};

export const actionCreators = {
    getSettings: (navigate) => async (dispatch) => {
        dispatch({ type: requestSettingsType });
        try {
            const response = await ApiClient.get(`api/settings`, navigate, dispatch);
            dispatch({ type: completeSettingsType, settings: response.data });
        } catch {
            dispatch({ type: errorType });
        }
    },
    updateSettings: (data, navigate) => async (dispatch) => {
        dispatch({ type: requestUpdateSettingsType });
        try {
            const response = await ApiClient.post(`api/settings`, data, navigate, dispatch);
            dispatch({ type: completeUpdateSettingsType, settings: response.data });
        } catch {
            dispatch({ type: errorType });
        }
    },
    resetState: () => async (dispatch) => {
        dispatch({ type: resetStateType });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestSettingsType) {
        return {
            ...state,
            initialLoading: true,
            loading: true
        };
    }

    if (action.type === requestUpdateSettingsType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === completeSettingsType) {
        return {
            ...state,
            settings: action.settings,
            initialLoading: false,
            loading: false
        };
    }

    if (action.type === completeUpdateSettingsType) {
        return {
            ...state,
            settings: action.settings,
            loading: false
        };
    }

    if (action.type === errorType) {
        return {
            ...state,
            loading: false
        };
    }

    if (action.type === resetStateType) {
        return {
            ...initialState
        };
    }

    return state;
};