import React from "react";
import { actionCreators } from "../store/LoginState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Paper, Grid } from "@mui/material";
import {
    FormContainer, FormSubmitButton, FormTextField
} from "../form";

import { useNavigate } from "react-router-dom";
import { useAppStyles } from "../styles";

const LoginForm = props => {
    const { classes: appClasses } = useAppStyles();

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
           <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Вход</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name="username"
                    label="Потребител *"
                    rules={{ required: true }}
                    errorMessage={"Въведете потребител."}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name="password"
                    label="Парола *"
                    rules={{ required: true }}
                    type="password"
                    errorMessage={"Въведете парола."}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12} className={appClasses.formActions}>
                <FormSubmitButton>Влез</FormSubmitButton>
            </Grid>
        </Grid>
    );
};


const LoginPage = props => {
    const { login, loading } = props;
    const navigate = useNavigate();

    return (
        <FormContainer isReadOnly={false} handleSubmit={data => login(data, navigate)} isLoading={loading} isSkeleton={false}>
            <LoginForm />
        </FormContainer>
    );
}

export default connect(
    (state) => state.login,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(LoginPage)
