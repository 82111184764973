import ApiClient from "../services/ApiClient";

const requestGetResultType = "REQUEST_GET_RESULT";
const completeGetResultType = "COMPLETE_GET_RESULT";

const errorType = "ERROR_RESULT";
const resetStateType = "RESET_RESULT_STATE_TYPE";

const initialState = {
    loading: true,
    result: null
};

export const actionCreators = {
    getData: (id, navigate) => async (dispatch) => {
        dispatch({ type: requestGetResultType });
        try {
            const response = await ApiClient.get(`api/result/${id}`, navigate, dispatch);
            dispatch({ type: completeGetResultType, result: response.data });
        } catch {
            dispatch({ type: errorType });
        }
    },
    resetState: () => async (dispatch) => {
        dispatch({type: resetStateType});
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestGetResultType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === completeGetResultType) {
        return {
            ...state,
            loading: false,
            result: action.result
        };
    }

    if (action.type === errorType) {
        return {
            ...state,
            loading: false
        };
    }

    if (action.type === resetStateType) {
        return { ...initialState };
    }

    return state;
};