import React, { useEffect } from "react";
import { actionCreators } from "../store/CreateRequestState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Paper, Grid, Box } from "@mui/material";
import {
    FormContainer, FormSubmitButton, selectRequiredRule, FormSelect, FormNumericField, FormCheckboxField
} from "../form";

import { useNavigate } from "react-router-dom";
import { useAppStyles } from "../styles";
import { useWatch, useFormContext } from "react-hook-form";

const RequestForm = props => {
    const { clients } = props;
    const { classes: appClasses } = useAppStyles();
    const { setValue } = useFormContext();

    const clientId = useWatch({
        name: "clientId",
        defaultValue: clients[0].value
    });

    useEffect(() => {
        if (clientId !== "") {
            setValue("salary", clients.find(c => c.value === clientId).salary);
        } else {
            setValue("salary", 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    const selectedClient = clients.find(c => c.value === clientId);

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Нова заявка</Typography>
            </Grid>
            <Grid container item xs={6} className={appClasses.requestInputWrapper}>
                <Grid item xs={12}>
                    <FormSelect
                        name="clientId"
                        label="Клиент *"
                        className={appClasses.formTextField}
                        options={clients}
                        defaultValue={clients[0].value}
                        rules={{
                            validate: {
                                required: selectRequiredRule("Изберете клиент.", false, "")
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormNumericField
                        numericType="decimal"
                        name="requestedAmount"
                        label="Размер на кредита *"
                        minValue={100}
                        maxValue={100000}
                        displayRange={true}
                        rules={{ required: true }}
                        errorMessage={"Въведете размер на кредита."}
                        className={appClasses.formTextField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormNumericField
                        numericType="integer"
                        name="creditDuration"
                        label="Срок на кредита (месеци) *"
                        minValue={1}
                        maxValue={120}
                        displayRange={true}
                        rules={{ required: true }}
                        errorMessage={"Въведете срок на кредита."}
                        className={appClasses.formTextField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormNumericField
                        numericType="decimal"
                        name="salary"
                        label="Месечен доход *"
                        minValue={200}
                        displayRange={true}
                        rules={{ required: true }}
                        errorMessage={"Въведете месечен доход."}
                        className={appClasses.formTextField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormCheckboxField
                        name="useClientGroupPreferences"
                        label="Използвай лихвена добавка спрямо клиентската група"
                        defaultValue={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormCheckboxField
                        name="useRequestedAmountPreferences"
                        label="Използвай лихвена добавка спрямо размера на кредита"
                        defaultValue={false}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} className={appClasses.profileWrapper}>
                <Grid item xs={12}>
                    <Typography variant="h6">Профил на клиента</Typography>
                    { clientId === "" && (<Typography className={appClasses.noProfileMsg}>Моля, изберете клиент.</Typography>) }
                </Grid>
                { clientId !== "" && (
                    <>
                        <Grid item xs={12}>
                            <Typography component="div">Възраст: {<Box component="span" className={appClasses.profileValue}>{selectedClient.age}</Box>}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">Област по местоживеене: {<Box component="span" className={appClasses.profileValue}>{selectedClient.region}</Box>}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">Категория: {<Box component="span" className={appClasses.profileValue}>{selectedClient.category}</Box>}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">Работодателска група: {<Box component="span" className={appClasses.profileValue}>{selectedClient.employerGroup}</Box>}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">Средна сума теглене ATM: {<Box component="span" className={appClasses.profileValue}>{selectedClient.atmWithdrawalAvarage} лева</Box>}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">Брой тегления ATM: {<Box component="span" className={appClasses.profileValue}>{selectedClient.atmWithdrawalCount}</Box>}</Typography>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12} className={appClasses.formActions}>
                <FormSubmitButton>Изпрати</FormSubmitButton>
            </Grid>
        </Grid>
    );
};


const CreateRequestPage = props => {
    const { createRequest, getClients, loading, clients } = props;
    const navigate = useNavigate();

    useEffect(() => {
        getClients(navigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormContainer isReadOnly={false} handleSubmit={data => createRequest(data, navigate)} isLoading={loading} isSkeleton={false}>
            <RequestForm clients={clients} />
        </FormContainer>
    );
}

export default connect(
    (state) => state.createRequest,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(CreateRequestPage)
