import axios from "axios";
import Cookies from "js-cookie";

export const requestHandler = (config) => {
    return config;
};

const errorHandler = error => {
    const response = error.response;
    const router = error.config.router;
    const store = error.config.store;
   
    if(response.status === 401 && router && store) {
        store.dispatch({ type: "LOGOUT" });       
        router.navigate("/login");
    }  else {
        console.error(response.status, response.data);
        alert("Something happened. If the problem persists, please contact support.");
    }

    return Promise.reject({ ...error });
};

export const successHandler = (response) => {
    return response;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    withCredentials: true,
    headers: {
        // get: {
        //     "Cache-Control": "no-cache",
        //     "Pragma": "no-cache",
        //     "Expires": "Sat, 01 Jan 2000 00:00:00 GMT",
        //     "If-Modified-Since": "0"
        // }
    }
});

const defaultTimeout = 20000; // 20 seconds
class ApiClient {
    constructor() {
        api.interceptors.request.use(
            request => requestHandler(request)
        );
        api.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );

        api.defaults.timeout = defaultTimeout;
    }

    defaultOptions = {};

    get(url, routerNavigate, storeDispatch, options = {}) {
        return api.get(url, { ...this.defaultOptions, ...options, router: { navigate: routerNavigate }, store: { dispatch: storeDispatch } });
    }

    post(url, data, routerNavigate, storeDispatch, options = {}) {
        return api.post(url, data, { ...this.defaultOptions, ...options, router: { navigate: routerNavigate }, store: { dispatch: storeDispatch } });
    }

    put(url, data, routerNavigate, storeDispatch, options = {}) {
        return api.put(url, data, { ...this.defaultOptions, ...options, router: { navigate: routerNavigate }, store: { dispatch: storeDispatch } });
    }

    delete(url, routerNavigate, storeDispatch, data, options = {}) {
        return api.delete(url, { ...this.defaultOptions, ...options, router: { navigate: routerNavigate }, store: { dispatch: storeDispatch }, data: data });
    }

    cancelTokenSource() {
        return axios.CancelToken.source();
    }
}

export default new ApiClient();