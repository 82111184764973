import React, { useEffect } from "react";
import { actionCreators } from "../store/RequestResultState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Paper, Grid, Box, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useAppStyles } from "../styles";
import { useTheme } from '@mui/material/styles';
import SellIcon from '@mui/icons-material/Sell';
import { TextSkeleton } from "../form/Skeleton";


const RequestInfo = props => {
    const { request, isSkeleton } = props;
    const theme = useTheme();

    if(isSkeleton) {
        return (<TextSkeleton sx={{ height: "130px", marginBottom: "25px", width: "100%" }} />);
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", border: "2px solid", borderColor: theme.palette.primary.main, borderRadius: "8px", padding: "15px" }}>
            <Box sx={{ marginLeft: "5px" }}>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>ЗАЯВКА:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>КЛИЕНТ:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>РАЗМЕР НА КРЕДИТА:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>СРОК НА КРЕДИТА:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>МЕСЕЧЕН ДОХОД:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>ДОБАВКА СПРЯМО КАТЕГОРИЯ:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>ДОБАВКА СПРЯМО РАЗМЕР:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>ВЪЗРАСТ:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>ОБЛАСТ ПО МЕСТОЖИВЕЕНЕ:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>КАТЕГОРИЯ:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>РАБОТОДАТЕЛСКА ГРУПА:</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>СРЕДНА СУМА ТЕГЛЕНЕ АТМ:</Typography>
                <Typography sx={{ display: "block" }}>БРОЙ ТЕГЛЕНИЯ АТМ:</Typography>
            </Box>
            <Box sx={{ color: theme.palette.primary.main, marginLeft: "35px" }}>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.id}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.clientId}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.requestedAmount} лева</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.creditDuration} месеца</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.salary} лева</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.useClientGroupPreferences ? "ДА" : "НЕ"}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.useRequestedAmountPreferences ? "ДА" : "НЕ"}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.age}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.region}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.category}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.employerGroup}</Typography>
                <Typography sx={{ display: "block", marginBottom: "15px" }}>{request.atmWithdrawalAvarage} лева</Typography>
                <Typography sx={{ display: "block" }}>{request.atmWithdrawalCount}</Typography>
            </Box>
        </Box>);
}

const Interest = props => {
    const { value } = props;
    const theme = useTheme();

    return (
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", border: "1px solid", borderColor: theme.palette.success.main, borderRadius: "8px", padding: "8px" }}>
            <Box>
                <Box>
                    <SellIcon fontSize="large" sx={{ fontSize: "45px", color: theme.palette.success.main }} />
                </Box>
            </Box>
            <Box sx={{ color: theme.palette.success.main, marginLeft: "10px" }}>
                <Typography sx={{ display: "block" }}>ЛИХВА {value} %</Typography>
            </Box>
        </Box>);
};

const RequestResultPage = props => {
    const { getData, resetState, loading, result } = props;
    const { classes: appClasses } = useAppStyles();
    const { requestId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getData(requestId, navigate);

        return () => {
            resetState();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Grid container className={appClasses.formWrapper} component={Paper}>
                <Grid item className={appClasses.loadingIndicator} xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
            {result &&
                <Grid container item spacing={2} className={appClasses.resultWrapper} xs={12}>
                    <Grid item xs={12} sm={9}>
                        <RequestInfo request={result} />
                    </Grid>
                    {
                        result && (
                            <Grid item xs={12} sm={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Interest value={result.interest} />
                            </Grid>
                        )
                    }
                </Grid>}
        </Grid>
    );
}

export default connect(
    (state) => state.requestResult,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(RequestResultPage)
