import ApiClient from "../services/ApiClient";

const setUserType = "SET_AUTHENTICATED_USER";
const logoutType = "LOGOUT";

const initialState = {
    authenticatedUser: null
};

export const actionCreators = {
    logout: (navigate) => async (dispatch) => {
        try {
            await ApiClient.post(`api/logout`, null, navigate, dispatch);
            dispatch({ type: logoutType });
            navigate("/login");
        } catch {
            // noop
        }
    },
    setUser: (username) => async (dispatch, getState) => {
        const currentUser = getState().app.authenticatedUser;
        if(currentUser !== username) {
            dispatch({ type: setUserType, user: username });
        }        
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if(action.type === setUserType) {
        return {
            ...state,
            authenticatedUser: action.user
        }
    }

    if(action.type === logoutType) {
        return {
            ...state,
            authenticatedUser: null
        }
    }

    return state;
};