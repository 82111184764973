import React, { useEffect } from "react";
import { useAuthenticatedUser } from "./auth";
import { useNavigate } from "react-router-dom";

export const RequireAuthentication = props => {
	const { children, setUser } = props;
    const navigate = useNavigate();
	const authenticatedUserFromCookie = useAuthenticatedUser();

    useEffect(() => {
        if(authenticatedUserFromCookie == null) {
            setUser(null);
            navigate("/login");
        }

        setUser(authenticatedUserFromCookie);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

	return (authenticatedUserFromCookie
		? <> { children } </>
		: <></>);
};