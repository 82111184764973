import { configureStore } from "@reduxjs/toolkit";
import * as App from "./AppState";
import * as CreateRequest from "./CreateRequestState";
import * as RequestList from "./RequestListState";
import * as RequestResult from "./RequestResultState";
import * as Settings from "./SettingsState";
import * as Login from "./LoginState";

export default configureStore({
    reducer: {
        app: App.reducer,
        createRequest: CreateRequest.reducer,
        requestList: RequestList.reducer,
        requestResult: RequestResult.reducer,
        settings: Settings.reducer,
        login: Settings.reducer
    },
});