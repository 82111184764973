import { makeStyles } from "tss-react/mui";

export const useAppStyles = makeStyles({ name: "App" })((theme, params, classes) => ({
    title: {
        marginBottom: theme.spacing(5),
        [`& h1`]: {
            textAlign: "center",
            color: theme.palette.primary.main
        },
        [`& p`]: {
            textAlign: "center",
        }
    },
    formTextField: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    formWrapper: {
        padding: theme.spacing(3, 7, 5, 7)
    },
    formSectionTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [`& h6`]: {
            color: theme.palette.secondary.main,
            borderBottom: "1px solid",
            borderColor: theme.palette.secondary.dark,
            paddingBottom: theme.spacing(2),
        }
    },
    formSubSectionTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [`& h6`]: {
            color: theme.palette.secondary.main,
            borderBottom: "1px solid",
            borderColor: theme.palette.secondary.dark,
            paddingBottom: theme.spacing(1),
            fontSize: "14px"
        }
    },
    formActions: {
        marginTop: theme.spacing(3),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    stickyFormActions: {
        position: "sticky",
        bottom: 0,
        background: "rgb(30,30,30)",
        paddingTop: "16px",
        paddingBottom: "16px",
        zIndex: 200
    },
    secondaryAction: {
        marginTop: theme.spacing(3)
    },
    stepsWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(5)
    },
    step: {
        margin: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        [`& .${classes.stepAvatar}`]: {
            border: "2px solid",
            marginRight: theme.spacing(2),
            borderColor: theme.palette.secondary.main,
            backgroundColor: "rgba(0,0,0,0)",
            color: theme.palette.secondary.main
        },
        [`&.${classes.activeStep} .${classes.stepAvatar}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    },
    activeStep: {},
    stepAvatar: {},
    stepText: {},
    loadingIndicator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px"
    },
    resultWrapper: {

    },
    resultSection: {
        padding: theme.spacing(2),
        [`& .${classes.sectionContent}`]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "2px solid",
            borderColor: theme.palette.secondary.dark,
            borderRadius: "5px",
            padding: theme.spacing(2, 2, 0, 2)
        }
    },
    sectionContent: {},
    textNegative: {
        color: theme.palette.error.main
    },
    textPositive: {
        color: theme.palette.success.main
    },
    decision: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        fontSize: "32px",
        [`& svg`]: {
            marginLeft: "3px"
        }
    },
    green: {
        color: theme.palette.success.main
    },
    red: {
        color: theme.palette.error.main
    },
    probabilityWrapper: {
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: "120px",
        padding: "8px",
        border: "4px solid",
        fontSize: "32px",
        fontWeight: 500,
        marginBottom: theme.spacing(5),
        [`&.${classes.negative}`]: {
            color: "#f44336"
        },
        [`&.${classes.positive}`]: {
            color: "#66bb6a"
        }
    },
    negative: {},
    positive: {},
    profileWrapper: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: "5px",
        padding: theme.spacing(1)
    },
    noProfileMsg: {
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(2)
    },
    profileValue: {
        color: theme.palette.primary.main
    },
    requestInputWrapper: {
        paddingRight: theme.spacing(2)
    }
}));