import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { TextSkeleton } from "./Skeleton";
import { useFormContext, Controller } from "react-hook-form";
import { parseBigNumber, nonEmptyString } from "./utils";
import { findError } from "./formHelpers";
import { useFormBehaviorContext } from "./FormBehaviorContext";

const decimalMask = /^(-?0?\.?|-?|-?[1-9]\d*\.?\d{0,2}|-?0\.\d{0,2})$/;
const integerMask = /^(0?|-?|-?[1-9]\d*)$/;

export const NumericTypes = {
	integer: "integer",
	decimal: "decimal"
};

export const NumericOutputTypes = {
	string: "string",
	number: "number"
};

export const testIntegerMask = (value) => {
	if (value != null) {
		return integerMask.test(value);
	}
	return true;
};

export const testDecimalMask = (value) => {
	if (value != null) {
		return decimalMask.test(value);
	}
	return true;
};

const CustomNumericField = props => {
	const {
		value, readOnly, minValue, maxValue, numericType, outputType,
		maxLength, onChange, onBlur, customDefaultValue, ...rest
	} = props;

	// If default value is set and value is not set always apply default value.
	useEffect(() => {
		if (customDefaultValue && value == null) {
			onChange(customDefaultValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	});

	const convertOutput = (value) => {
		if (outputType === NumericOutputTypes.number) {
			if (value === "") return null;

			return numericType === NumericTypes.integer
				? parseInt(value)
				: parseFloat(value);
		}

		return value;
	};

	const normalizeValue = (stringValue) => {
		const asNumber = parseBigNumber(stringValue);
		if (asNumber) {
			if (minValue && asNumber.lt(minValue)) {
				return convertOutput(minValue.toString());
			} else if (maxValue && asNumber.gt(maxValue)) {
				return convertOutput(maxValue.toString());
			} else {
				return convertOutput(asNumber.toString());
			}
		} else {
			return convertOutput("");
		}
	};

	const handleBlur = e => {
		const normalized = normalizeValue(e.target.value);
		onChange(normalized);
		onBlur(normalized);
	};

	const handleChange = e => {
		if ((numericType === NumericTypes.integer && testIntegerMask(e.target.value))
			|| (numericType === NumericTypes.decimal && testDecimalMask(e.target.value))) {
			onChange(e.target.value);
		}
	};

	return (
		<TextField
			value={value ?? ""}
			variant="outlined"
			margin="none"
			onBlur={handleBlur}
			onChange={handleChange}
			InputProps={{
				readOnly: readOnly
			}}
			inputProps={{
				maxLength: maxLength
			}}
			{...rest}
		/>
	);
};

export const FormNumericField = props => {
	const behavior = useFormBehaviorContext();

	const { name, readOnly: readOnlyProp, disabled: disabledProp, rules,
		errorMessage: errorMessageProp, helperText: helperTextProp,
		minValue, maxValue, numericType, displayRange, isSkeleton, defaultValue, ...rest } = props;

	const readOnly = readOnlyProp || behavior?.isReadOnly;
	const disabled = disabledProp || behavior?.isLoading || readOnly;

	const {
		control,
		trigger,
		formState: {
			errors
		}
	} = useFormContext();

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={70} />);
	}

	const error = findError(name, errors);
	const hasError = error != null;
	const errorMessage = nonEmptyString(error?.message, errorMessageProp);

	let helperText = helperTextProp;
	if (displayRange) {
		helperText = "";
		if(minValue != null) helperText += `от ${minValue?.toString()} `;
		if(maxValue != null) helperText += `до ${maxValue?.toString()}`;
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={""}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<CustomNumericField
					onBlur={value => {
						if (hasError) {
							trigger(name);
						}
						onBlur(value);
					}}
					onChange={value => onChange(value)}
					value={value}
					name={name}
					error={hasError}
					helperText={hasError ? errorMessage : helperText}
					disabled={disabled}
					readOnly={readOnly}
					numericType={numericType}
					minValue={minValue}
					maxValue={maxValue}
					customDefaultValue={defaultValue}
					{...rest}
				/>
			)}
		/>
	);
};

FormNumericField.propTypes = {
	numericType: PropTypes.oneOf([NumericTypes.decimal, NumericTypes.integer]),
	outputType: PropTypes.oneOf([NumericOutputTypes.string, NumericOutputTypes.number])
};

FormNumericField.defaultProps = {
	numericType: NumericTypes.decimal,
	outputType: NumericOutputTypes.number,
	helperText: " "
};

export default FormNumericField;