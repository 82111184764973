import ApiClient from "../services/ApiClient";

const requestCreateType = "REQUEST_CREATE";
const completeCreateType = "COMPLETE_CREATE";

const requestGetClientsType = "REQUEST_GET_CLIENTS";
const completeGetClientsType = "COMPLETE_GET_CLIENTS";

const errorType = "ERROR_CREATE";

const initialState = {
    loading: false,
    clients: [ { value: "", label: "не е избран" } ]
};

export const actionCreators = {
    getClients: (navigate) => async (dispatch) => {
        dispatch({ type: requestGetClientsType });
        try {
            const response = await ApiClient.get(`api/client/list`, navigate, dispatch);
            dispatch({ type: completeGetClientsType, clientList: response.data });
        } catch {
            dispatch({ type: errorType });
        }
    },
    createRequest: (data, navigate) => async (dispatch) => {
        dispatch({ type: requestCreateType });
        try {
            const response = await ApiClient.post(`api/request`, data, navigate, dispatch);
            dispatch({ type: completeCreateType });
            navigate(`/result/${response.data.id}`);
        } catch {
            dispatch({ type: errorType });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestCreateType || action.type === requestGetClientsType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === completeCreateType) {
        return {
            ...state,
            loading: false
        };
    }

    if (action.type === completeGetClientsType) {
        return {
            ...state,
            clients: [ 
                ...initialState.clients, 
                ...action.clientList.clients.map(c => { return { value: c.clientId, label: c.clientId, ...c }}) 
            ],
            loading: false
        };
    }

    if (action.type === errorType) {
        return {
            ...state,
            loading: false
        };
    }

    return state;
};