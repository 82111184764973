import React, { useEffect } from "react";
import clsx from "clsx";
import { actionCreators } from "../store/SettingsState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Paper, Grid, CircularProgress, Box, useTheme } from "@mui/material";
import {
	FormContainer, FormSubmitButton, FormNumericField, FormHiddenField, FormGeneralErrorMessage
} from "../form";

import { useNavigate } from "react-router-dom";
import { useAppStyles } from "../styles";
import { useFormContext } from "react-hook-form";

const getCreditAmountRange = (amountBin) => {
	const minIncluding = amountBin.charAt(0) === '[';
	const maxIncluding = amountBin.charAt(amountBin.length - 1) === ']';
	amountBin = amountBin.substring(1, amountBin.length - 1);

	const min = parseFloat(amountBin.split("; ")[0]);
	const maxRaw = amountBin.split("; ")[1];
	const max = maxRaw === "inf" ? null : parseFloat(maxRaw);

	return {
		min: minIncluding ? min : min + 1,
		max: maxIncluding || max == null ? max : max - 1
	}
}

const OptimalScenarioRow = props => {
	const { scenario, index } = props;
	const { classes: appClasses } = useAppStyles(); 
	const theme = useTheme();

	const buildFormName = (propName) => `optimalScenarios.${index}.${propName}`;
	const creditAmountRange = getCreditAmountRange(scenario["CL_AMOUNT_BIN"]);

	return (
		<Grid container item xs={12} spacing={2}>
			<FormHiddenField name={buildFormName("SELECTED_EMPLOYER")} defaultValue={scenario["SELECTED_EMPLOYER"]}/>
			<FormHiddenField name={buildFormName("CL_AMOUNT_BIN")} defaultValue={scenario["CL_AMOUNT_BIN"]} />
			<FormHiddenField name={buildFormName("INCOME_BIN")} defaultValue={scenario["INCOME_BIN"]} />
			
			<Grid item xs={12} className={appClasses.formSubSectionTitle}>
				<Typography variant="h6">
					<Box component="span"> РАБОТОДАТЕЛ: </Box>
					<Box component="span" sx={{color: theme.palette.primary.main}}>{scenario["SELECTED_EMPLOYER"]}</Box>
					<Box component="span"> КРЕДИТ: </Box>
					<Box component="span" sx={{color: theme.palette.primary.main}}>{scenario["CL_AMOUNT_BIN"]}</Box>
					<Box component="span"> ДОХОД: </Box>
					<Box component="span" sx={{color: theme.palette.primary.main}}>{scenario["INCOME_BIN"]}</Box>
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("AVERAGE_CL_AMOUNT")}
					label="СРЕДЕН РАЗМЕР НА КРЕДИТА *"
					rules={{ required: true }}
					helperText=""
					displayRange={true}
					minValue={creditAmountRange.min}
					maxValue={creditAmountRange.max}
					className={appClasses.formTextField}
					defaultValue={scenario["AVERAGE_CL_AMOUNT"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("INTEREST_RATE_LOWER_BOUNDARY")}
					label="МИНИМАЛНА ЛИХВА *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["INTEREST_RATE_LOWER_BOUNDARY"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("INTEREST_RATE_UPPER_BOUNDARY")}
					label="МАКСИМАЛНА ЛИХВА *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["INTEREST_RATE_UPPER_BOUNDARY"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("RATIO_BELOW_50")}
					label="ЛИХВЕНА ДОБАВКА I *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["RATIO_BELOW_50"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("RATIO_BELOW_10")}
					label="ЛИХВЕНА ДОБАВКА II *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["RATIO_BELOW_10"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("RATIO_ABOVE_20")}
					label="ЛИХВЕНА ДОБАВКА III *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["RATIO_ABOVE_20"]}
				/>
			</Grid>

			<Grid item xs={6}>
				<FormNumericField
					numericType="decimal"
					name={buildFormName("RATIO_ABOVE_100")}
					label="ЛИХВЕНА ДОБАВКА IV *"
					rules={{ required: true }}
					helperText=""
					className={appClasses.formTextField}
					defaultValue={scenario["RATIO_ABOVE_100"]}
				/>
			</Grid>
		</Grid>
	);
}

const SettingsForm = props => {
	const { settings } = props;
	const { classes: appClasses } = useAppStyles();
	const { reset } = useFormContext(); 

	useEffect(() => {
		reset(settings);
	}, [settings, reset]);

	return (
		<Grid container className={appClasses.formWrapper} component={Paper}>
		   <Grid item xs={12} className={appClasses.formSectionTitle}>
				<Typography variant="h6">Настройки</Typography>
			</Grid>
			
			{ settings.optimalScenarios.map((scenario, index) => (
				<OptimalScenarioRow 
					key={`${scenario["SELECTED_EMPLOYER"]} | ${scenario["CL_AMOUNT_BIN"]} | ${scenario["INCOME_BIN"]}`} 
					scenario={scenario} 
					index={index} 
				/>
			)) }

			<Grid item xs={12} className={clsx(appClasses.formActions, appClasses.stickyFormActions)}>
				<FormSubmitButton>Запази</FormSubmitButton>
				<FormGeneralErrorMessage />
			</Grid>
		</Grid>
	);
};


const SettingsPage = props => {
	const { getSettings, updateSettings, resetState, initialLoading, loading, settings } = props;
	const navigate = useNavigate();
	const { classes: appClasses } = useAppStyles();

	useEffect(() => {
		getSettings(navigate);
		return () => {
			resetState();
		};
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (initialLoading) {
        return (
            <Grid container className={appClasses.formWrapper} component={Paper}>
                <Grid item className={appClasses.loadingIndicator} xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

	return (
		<FormContainer isReadOnly={false} handleSubmit={data => updateSettings(data, navigate)} isLoading={loading} isSkeleton={false}>
			<SettingsForm settings={settings} />
		</FormContainer>
	);
}

export default connect(
	(state) => state.settings,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(SettingsPage)
